import { AuthorStore, TagStore, UserStore } from "@/app/providers/MobxStore";
import { ROUTES } from "@/app/providers/Router/model/consts/routerConsts";
import {
  getAuthorCreatorOpened,
  getFaqArticlesModalEditorOpen,
  getFaqTagsCreatorOpened,
  getJournalArticlesModalEditorOpen,
  getJournalTagCreatorOpened,
  getSeoTagCreatorOpened,
} from "@/features/UI";
import { uiActions } from "@/features/UI";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { FaqArticlesModalEditor } from "@/widgets/FaqArticlesModalEditor";
import { FaqTagsModal } from "@/widgets/FaqTagsModal";
import {
  useGetFaqArticleList,
  useGetFaqTagList,
  useGetSeoInfoList,
} from "@op/entities";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import AuthorModal from "./Authors/AuthorModal";
import JournalArticlesModalEditor from "./JournalArticles/JournalArticlesModalEditor";
import JournalTagsModal from "./JournalTags/JournalTagsModal";
import ProfileAside from "./ProfileAside/ProfileAside";
import ProfileHeader from "./ProfileHeader";
import SeoInfoModal from "./SeoInfo/SeoInfoModal";

const ProfileContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const journalArticlesModalEditorOpen = useSelector(
    getJournalArticlesModalEditorOpen
  );
  const faqArticlesModalEditorOpen = useSelector(getFaqArticlesModalEditorOpen);
  const authorCreatorOpened = useSelector(getAuthorCreatorOpened);
  const seoTagCreatorOpened = useSelector(getSeoTagCreatorOpened);
  const journalTagCreatorOpened = useSelector(getJournalTagCreatorOpened);
  const faqTagsCreatorOpened = useSelector(getFaqTagsCreatorOpened);

  const { refetch: refetchFaqArticlesData } = useGetFaqArticleList({
    token: UserStore.token!,
  });
  const { refetch: refetchSeoInfoList } = useGetSeoInfoList();
  const { refetch: refetchFaqTagsList } = useGetFaqTagList({});

  const handleJouralArticleCreatorModalOnSave = useCallback(() => {
    navigate(ROUTES.HOME.path);
  }, [navigate]);

  const handleFaqArticleCreatorOnSave = useCallback(() => {
    refetchFaqArticlesData();
    navigate(ROUTES.FAQ.ARTICLES.path);
  }, [navigate, refetchFaqArticlesData]);

  const handleJournalTagCreatorOnSave = useCallback(() => {
    TagStore.fetchTags();
    navigate(ROUTES.JOURNAL.TAGS.path);
  }, [navigate]);

  const handleAuthorCreatorOnSave = useCallback(() => {
    AuthorStore.fetchAuthors();
    navigate(ROUTES.GENERAL.AUTHORS.path);
  }, [navigate]);

  const handleFaqTagCreatorOnSave = useCallback(() => {
    refetchFaqTagsList();
    navigate(ROUTES.FAQ.TAGS.path);
  }, [navigate, refetchFaqTagsList]);

  const handleSeoTagCreatorOnSave = useCallback(() => {
    refetchSeoInfoList();
    navigate(ROUTES.GENERAL.SEO_TAGS.path);
  }, [navigate, refetchSeoInfoList]);

  const handleJournalArticlesModalEditorClose = useCallback(() => {
    dispatch(uiActions.setJournalArticlesModalEditorOpen(false));
  }, [dispatch]);

  const handleFaqArticlesModalEditorClose = useCallback(() => {
    dispatch(uiActions.setFaqArticlesModalEditorOpen(false));
  }, [dispatch]);

  const handleAuthorCreatorClose = useCallback(() => {
    dispatch(uiActions.setAuthorCreatorOpen(false));
  }, [dispatch]);

  const handleSeoTagCreatorClose = useCallback(() => {
    dispatch(uiActions.setSeoTagCreatorOpen(false));
  }, [dispatch]);

  const handleJournalTagCreatorClose = useCallback(() => {
    dispatch(uiActions.setJournalTagCreatorOpen(false));
  }, [dispatch]);

  const handleFaqTagCreatorClose = useCallback(() => {
    dispatch(uiActions.setFaqTagsCreatorOpen(false));
    refetchFaqTagsList();
  }, [dispatch, refetchFaqTagsList]);

  return (
    <>
      <div className="profile-section">
        <div className="wrap">
          <div className="profile profile-page">
            <ProfileHeader />

            <div className="profile-page__columns">
              <ProfileAside />
              <div className="profile-page__center">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuthorModal
        open={authorCreatorOpened}
        onClose={handleAuthorCreatorClose}
        data={null}
        onSave={handleAuthorCreatorOnSave}
      />
      <SeoInfoModal
        open={seoTagCreatorOpened}
        onClose={handleSeoTagCreatorClose}
        data={null}
        onSave={handleSeoTagCreatorOnSave}
      />
      <JournalArticlesModalEditor
        open={journalArticlesModalEditorOpen}
        onClose={handleJournalArticlesModalEditorClose}
        data={null}
        onSave={handleJouralArticleCreatorModalOnSave}
      />
      <JournalTagsModal
        open={journalTagCreatorOpened}
        onClose={handleJournalTagCreatorClose}
        data={null}
        onSave={handleJournalTagCreatorOnSave}
      />
      <FaqArticlesModalEditor
        open={faqArticlesModalEditorOpen}
        onClose={handleFaqArticlesModalEditorClose}
        onSave={handleFaqArticleCreatorOnSave}
      />
      <FaqTagsModal
        open={faqTagsCreatorOpened}
        onClose={handleFaqTagCreatorClose}
        onSave={handleFaqTagCreatorOnSave}
      />
    </>
  );
};

export default observer(ProfileContainer);
