import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { UISchema } from "../types/UISchema";

const initialState: UISchema = {
  journalArticlesModalEditorOpen: false,
  faqArticlesModalEditorOpen: false,
  authorCreatorOpen: false,
  seoTagCreatorOpen: false,
  journalTagCreatorOpen: false,
  faqTagsCreatorOpen: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setJournalArticlesModalEditorOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.journalArticlesModalEditorOpen = payload;
    },
    setFaqArticlesModalEditorOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.faqArticlesModalEditorOpen = payload;
    },
    setAuthorCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.authorCreatorOpen = payload;
    },
    setSeoTagCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.seoTagCreatorOpen = payload;
    },
    setJournalTagCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.journalTagCreatorOpen = payload;
    },
    setFaqTagsCreatorOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.faqTagsCreatorOpen = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { actions: uiActions } = uiSlice;
export const { reducer: uiReducer } = uiSlice;
