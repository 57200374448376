import { Select } from "@/widgets/Form";
import { IFaqTag } from "@op/entities";
import classNames from "classnames";
import { t } from "i18next";
import { useMemo } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";

interface IProps {
  name: string;
  items?: IFaqTag[];
  control: Control<any>;
  errors: FieldErrors;
  disabled?: boolean;
}

export const SelectTagController = ({
  name,
  items,
  errors,
  control,
  disabled,
}: IProps) => {
  const filteredItems = useMemo(
    () => items?.filter((tag) => tag.is_enabled),
    [items]
  );

  return (
    <Controller
      disabled={disabled}
      name={name}
      control={control}
      rules={{ required: t("ChooseTag") }}
      render={({ field: { value, onChange } }) => (
        <Select
          disabled={disabled}
          isInvalid={!!errors.index}
          className="editor-header__rubric"
          value={value}
          setValue={onChange}
          items={filteredItems?.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))}
          searchFunction={({ label, search }) =>
            label.toLowerCase().includes(search)
          }
          searchPlaceholder="Искать рубрику"
          button={(selectedItem) => (
            <button
              className={classNames(
                "btn btn-outline black scale-up lighten-up size-s",
                !!errors.index && "red"
              )}
            >
              <i className="btn__text select__btn-text">
                {selectedItem || "Выбрать рубрику"}
              </i>
              <span className="icon icon-cursor-down select__btn-icon" />
            </button>
          )}
          option={(item: string) => (
            <>
              <em className="dropdown__text select__text">{item}</em>
            </>
          )}
        />
      )}
    />
  );
};
