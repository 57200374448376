import { UserStore } from "@/app/providers/MobxStore";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { notify } from "@/shared/lib/utils/notify";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import Input from "@/widgets/Form/ui/Input";
import Radio from "@/widgets/Form/ui/Radio";
import {
  IFaqTag,
  useAddFaqTagMutation,
  useEditFaqTagMutation,
} from "@op/entities";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const defaultValues: Omit<IFaqTag, "id"> = {
  display_order: 0,
  is_enabled: true,
  name: "",
  title: "",
  index: "",
  seo_title: "",
  seo_description: "",
  icon: "",
};

type TFormFields = Omit<IFaqTag, "id">;

interface IProps extends IModalOpen {
  data?: IFaqTag;
  onSave?: () => void;
}

const FaqTagsModal = ({ open, onClose, data, onSave }: IProps) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [addFaqTag, { isLoading: isAddFaqTagLoading }] = useAddFaqTagMutation();
  const [editFaqTag, { isLoading: isEditFaqTagLoading }] =
    useEditFaqTagMutation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const handleSave = async (formData: TFormFields) => {
    const requestData = {
      ...formData,
      token: UserStore.token!,
    };

    const response = data
      ? await editFaqTag(requestData)
      : await addFaqTag(requestData);

    if ("error" in response) {
      notify(t("AnErrorOcurred"));
      return;
    }

    if ("data" in response) {
      onSave?.();
      onClose();
      reset(defaultValues);
    }
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
      reset(data || defaultValues);
    }
  }, [open, data, reset]);

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <form
          className="profile-modal__holder"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="profile-modal__header">
            {data ? (
              <span className="profile-modal__header-title">
                {t("TagEdit")}
              </span>
            ) : (
              <>
                <span className="profile-modal__header-title">
                  {t("TagAdd")}
                </span>
                <p className="profile-modal__header-text">
                  {t("TagAddDescription")}
                </p>
              </>
            )}
          </div>
          <div className="form profile-modal__form">
            <div className="form__block">
              <span className="label">{t("Name")}</span>
              <Input
                {...register("name", {
                  ...requiredValidateWhiteSpaces(t("PasteName")),
                })}
                isInvalid={!!errors.name}
                errorText={errors.name?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("Title")}</span>
              <Input
                {...register("title", {
                  ...requiredValidateWhiteSpaces(t("PasteTitle")),
                })}
                isInvalid={!!errors.title}
                errorText={errors.title?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("Identifier")}</span>
              <Input
                {...register("index", {
                  ...requiredValidateWhiteSpaces(t("PasteIdentifier")),
                  pattern: {
                    value: /^([a-zA-Z0-9_-]+)$/,
                    message: t("OnlyLatin"),
                  },
                })}
                isInvalid={!!errors.index}
                errorText={errors.index?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("TagSEOTitle")}</span>
              <Input
                {...register("seo_title", {
                  ...requiredValidateWhiteSpaces(t("PasteTitle")),
                })}
                isInvalid={!!errors.seo_title}
                errorText={errors.seo_title?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("TagSEODescription")}</span>
              <Input
                {...register("seo_description", {
                  ...requiredValidateWhiteSpaces(t("PasteSEODescription")),
                })}
                isInvalid={!!errors.seo_description}
                errorText={errors.seo_description?.message}
              />
            </div>
            <div className="form__block profile-modal__radio-block">
              <Controller
                name="is_enabled"
                control={control}
                render={({ field }) => (
                  <Radio
                    value={!!field.value}
                    onChange={field.onChange}
                    label={t("TagShowOnHome")}
                  />
                )}
              />
            </div>

            <div className="form__block">
              <span className="label">{t("Order")}</span>
              <Input
                type="number"
                {...register("display_order")}
                isInvalid={!!errors.display_order}
                errorText={errors.display_order?.message}
              />
            </div>
            <button
              className={classNames(
                "btn btn-fond scale-up lighten-up btn-full blue",
                Object.keys(errors).length > 0 && "btn-disabled"
              )}
              type="submit"
            >
              {data ? (
                <i className="btn__text">
                  {isEditFaqTagLoading || isAddFaqTagLoading
                    ? t("Saving")
                    : t("Save")}
                </i>
              ) : (
                <i className="btn__text">
                  {isAddFaqTagLoading || isEditFaqTagLoading
                    ? t("Saving")
                    : t("TagAdd")}
                </i>
              )}
            </button>
          </div>
        </form>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default observer(FaqTagsModal);
