import AuthorStore from "@/app/providers/MobxStore/model/AuthorStore";
import { ROUTES } from "@/app/providers/Router/model/consts/routerConsts";
import * as ArticleController from "@/controllers/article-controller";
import { ConfirmModal } from "@/features/Modal";
import { getJournalArticlesModalEditorOpen } from "@/features/UI";
import { useDidUpdateEffect } from "@/shared/lib/hooks/useDidUpdateEffect";
import useModalConfirm from "@/shared/lib/hooks/useModalComfirm";
import useModalOpen from "@/shared/lib/hooks/useModalOpen";
import { getConfig } from "@/shared/lib/utils/getConfig";
import { isFutureDate } from "@/shared/lib/utils/isFutureDate";
import { pagination } from "@/shared/lib/utils/pagination";
import { Input, Select } from "@/widgets/Form";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import CardArticleInfo from "../CardArticleInfo";
import CardArticleThumbnail from "../CardArticleThumbnail";
import ItemCard from "../ItemCard";
import PaginationArrows from "../PaginationArrows";
import ModalEditor from "./JournalArticlesModalEditor";
import {
  DATE_OPTIONS,
  EDateOptions,
  EFilterTabs,
  FILTER_TABS,
  PAGINATION_SIZE,
} from "./consts";

function JournalArticles() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();

  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [modalEditorData, setModalEditorData] =
    useState<Nullable<ArticleDetail>>(null);
  const [searchString, setSearchString] = useState("");
  const [articles, setArticles] = useState<ArticleResponse[]>([]);
  const [currentFilterTab, setCurrentFilterTab] = useState(EFilterTabs.all);
  const [currentDateFilter, setCurrentDateFilter] = useState(EDateOptions.all);
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);
  const journalArticlesModalEditorOpen = useSelector(
    getJournalArticlesModalEditorOpen
  );

  const getArticles = async () => {
    setLoading(true);
    setArticles([]);
    const articleResponse = await ArticleController.getAllArticles();

    setLoading(false);
    articleResponse && setArticles(articleResponse);
  };

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        articles.length,
        !!next
      )
    );
  };

  const handleFilterSelect = (value: number) => {
    setCurrentFilterTab(value);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const handleDateFilterChange = (date?: EDateOptions) => {
    if (date != null) {
      setCurrentDateFilter(date);
      setCurrentPaginationPosition(0);
    }
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = async () => {
    const response =
      modalConfirm.id &&
      (await ArticleController.deleteArticle(modalConfirm.id));
    if (response) {
      setArticles(
        articles.filter((article) => article.article_id !== modalConfirm.id)
      );
      setModalConfirm({ isOpen: false, id: null });
    }
  };

  const handleEdit = async (article_index: string) => {
    const response = await ArticleController.getArticleByIndex(article_index);
    if (response) {
      setModalEditorData(response);
      setModalEditorOpened(true);
    }
  };

  const handleClick = async (article_index: string) => {
    window.open(
      `${getConfig().REACT_APP_JOURNAL_URL}/${article_index}`,
      "_blank"
    );
  };

  const handleAdd = async () => {
    setModalEditorData(null);
    setModalEditorOpened(true);
  };

  const handleModalEditorOnSave = async (article: ArticleDetail) => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await getArticles();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  const filteredList = useMemo(() => {
    return articles
      .filter((article) => (currentFilterTab ? article.is_draft : true))
      .filter((article) =>
        article.article_name.toLowerCase().includes(searchString)
      )
      .filter((article) => {
        const articleAge = moment().diff(moment(article.date), "days");
        switch (currentDateFilter) {
          case EDateOptions.week:
            return articleAge <= 7;
          case EDateOptions.month:
            return articleAge <= 30;
          default:
            return true;
        }
      });
  }, [articles, currentDateFilter, currentFilterTab, searchString]);

  useEffect(() => {
    if (AuthorStore.data && !journalArticlesModalEditorOpen) {
      getArticles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuthorStore.data, journalArticlesModalEditorOpen]);

  useEffect(() => {
    const articleIndexFromHash = location.hash.slice(1);
    if (articleIndexFromHash) {
      articleIndexFromHash === "new-article"
        ? handleAdd()
        : handleEdit(articleIndexFromHash);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  useDidUpdateEffect(() => {
    !modalEditorOpened && navigate(ROUTES.HOME.path);
  }, [modalEditorOpened]);

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("ArticlesList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("AddArticle2")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <ul className="profile__tabs">
            {FILTER_TABS.map((tab) => (
              <li
                className={classNames(
                  "profile__tab",
                  currentFilterTab === tab.value && "is-active"
                )}
                key={tab.label}
                onClick={() => handleFilterSelect(tab.value)}
              >
                <div className="profile__tab-holder">
                  <strong className="profile__tab-name">{t(tab.label)}</strong>
                  <span className="profile__tab-number">
                    {tab.value === EFilterTabs.draft
                      ? articles.filter((article) => article.is_draft).length
                      : articles.length}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <div className="search-input profile__search">
              <Input
                placeholder={t("SearchByArticles")}
                className="input_stroke profile__search-input"
                value={searchString}
                onChange={handleSearchChange}
              />
            </div>
          </div>
          <div className="profile__control-block">
            <Select
              className="profile__filter"
              dropDownClassName="bottom bottom-end"
              value={currentDateFilter}
              setValue={handleDateFilterChange}
              items={DATE_OPTIONS}
              button={(label) => (
                <button className="btn btn-outline lighten-up scale-up black">
                  <i className="btn__text select__btn-text">{t(label!)}</i>
                  <span className="icon icon-cursor-down select__btn-icon"></span>
                </button>
              )}
              option={(item) => (
                <em className="dropdown__text select__text">{t(item)}</em>
              )}
            />
          </div>
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={filteredList.length}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-articles">
        {loading && <div>{t("Loading")}...</div>}

        {filteredList
          .slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((article) => (
            <ItemCard
              key={article.article_id}
              className={classNames(
                "card-article",
                article.is_draft && "is-unpublished"
              )}
              cardId={article.article_id}
              cardLink={article.article_index}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(article.article_index)}
              onClick={() => handleClick(article.article_index)}
              cells={[
                <CardArticleThumbnail
                  key={0}
                  thumbailUrl={article.cover_image}
                />,
                <CardArticleInfo
                  key={1}
                  title={article.article_name}
                  authorId={article.author_id}
                  articleIndex={article.article_index}
                />,
                <React.Fragment key={2}>
                  <p>{moment(article.date).format("DD MMMM YYYY")}</p>
                  {isFutureDate(article.date) && (
                    <p className="profile-articles__status red">
                      {t("Deffered")}
                    </p>
                  )}
                </React.Fragment>,
              ]}
            />
          ))}
        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={filteredList.length}
          />
        </div>
      </div>
      <ModalEditor
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("ArticleDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default withTranslation()(JournalArticles);
